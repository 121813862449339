import { useState } from "react"

import { SubscriptionStatus } from "src/components/Account/types"
import { NotEnoughPermissionsDialog } from "src/components/FeatureBlockers/NotEnoughPermissionsDialog"
import { ReactivateBlockerDialog } from "src/components/FeatureBlockers/ReactivateBlockerDialog"
import { StoreBlockerDialog } from "src/components/FeatureBlockers/StoreBlockerDialog"
import { UpgradeBlockerDialog } from "src/components/FeatureBlockers/UpgradeBlockerDialog"
import { CreateHomeConfirmStep } from "src/components/Homes/CreateHomeWizard/CreateHomeConfirmStep"
import {
  CREATE_HOME_FORM_ID,
  EMPTY_AUTOCOMPLETE_ADDRESS,
} from "src/components/Homes/CreateHomeWizard/createHomeConstants"
import {
  ISelectedOption,
  SelectSettingsProfileStep,
} from "src/components/Homes/CreateHomeWizard/SelectSettingsProfileStep"
import {
  HOME_ADDRESS_FORM_ID,
  HomeAddressStep,
} from "src/components/Homes/HomeAddress/HomeAddressStep"
import { HomeMapStep } from "src/components/Homes/HomeAddress/HomeMapStep"
import { isProdEnv } from "src/constants/env"
import { HREF_MINUT_STAGING_STORE, HREF_MINUT_STORE } from "src/constants/hrefs"
import {
  useFetchCallAssistActivationEstimate,
  useGetCallAssistActive,
} from "src/data/callAssist/queries/callAssistQueries"
import { useFeatureAvailability } from "src/data/featureAvailability/logic/useFeatureAvailability"
import { useIsHomeGroupAdmin } from "src/data/homeGroups/logic/useIsHomeGroupAdmin"
import { TMaybeHomeGroup } from "src/data/homeGroups/types/homeGroupTypes"
import { useFetchProfiles } from "src/data/homeProfiles/queries/HomeProfilesQueries"
import {
  useFetchHomeTokenCostEstimate,
  useFetchHomeTokens,
} from "src/data/homes/queries/homeTokenQueries"
import { IPostHome } from "src/data/homes/types/homeQueryTypes"
import { IAddress, ILocation } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { usePostHome } from "src/data/organizations/queries/homeQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { getAccessLogic } from "src/data/user/logic/accessLogic"
import { useFetchCreateHomePrice } from "src/data/user/queries/userQueries"
import { PLAN } from "src/data/user/user"
import { useEffectOnce } from "src/hooks/useEffectOnce"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { useRouter } from "src/router/useRouter"
import { FullscreenWizard } from "src/ui/Wizard/FullscreenWizard"
import { IWizardStep } from "src/ui/Wizard/wizardTypes"
import { ErrorService } from "src/utils/ErrorService"
import { getLocalTimeZone, getTimeZoneFromLoc } from "src/utils/l10n"

import { StepId } from "./createHomeTypes"
import { HomeInformationStep } from "./HomeInformationStep"

export function CreateHomeWizard() {
  const { t, langKeys } = useTranslate()
  const { navigate, goBack } = useRouter()

  const { orgId, org, refetch } = useOrganization()
  const { user_id, current_plan } = useGetUser()

  const postHome = usePostHome()

  const [currentStep, setCurrentStep] = useState(0)
  const [homeGroup, setHomeGroup] = useState<TMaybeHomeGroup>(null)
  const [homeName, setHomeName] = useState("")
  const [error, setError] = useState(false)
  const [homeLocation, setHomeLocation] = useState<ILocation>({
    latitude: 0,
    longitude: 0,
  })
  const [homeAddress, setHomeAddress] = useState<IAddress>(
    EMPTY_AUTOCOMPLETE_ADDRESS
  )

  const [addressSearchString, setAddressSearchString] = useState("")

  const [selectedSettingsProfile, setSelectedSettingsProfile] =
    useState<ISelectedOption>({
      id: "",
      name: "",
    })
  const [checkedTerms, setCheckedTerms] = useState(false)
  const [checkedTermsError, setCheckedTermsError] = useState(false)

  const createHomeAvailability = useFeatureAvailability({
    feature: "create_home",
    refresh: true,
  })
  const homeTokensAvailability = useFeatureAvailability({
    feature: "home_tokens",
    refresh: true,
  })

  const {
    callAssistIsActive,
    isInitialLoading: getCallAssistActiveIsInitialLoading,
    isError: getCallAssistActiveIsError,
  } = useGetCallAssistActive()

  const isHomegroupAdmin = useIsHomeGroupAdmin()
  const access = getAccessLogic({ role: org.user_role })
  const canAddHomeAccess = access.hasAdminAccess || isHomegroupAdmin.value

  useEffectOnce(() => {
    refetch()
  })

  const homeTokensFeatureAvailable = homeTokensAvailability.available

  // The enabled flag is required below because we still have Legacy Starter users.
  const fetchCreateHomePrice = useFetchCreateHomePrice({
    userId: user_id,
    options: {
      enabled:
        canAddHomeAccess &&
        (!homeTokensFeatureAvailable || current_plan !== PLAN.starter),
    },
  })

  const fetchHomeTokens = useFetchHomeTokens({
    orgId,
    filters: { activated: false },
    options: {
      enabled: canAddHomeAccess,
    },
  })

  const availableHomeTokensCount = fetchHomeTokens.data?.paging.total_count || 0

  const fetchProfiles = useFetchProfiles({
    orgId,
  })

  const profiles = fetchProfiles.data?.profiles || []

  const fetchHomeTokenCostEstimate = useFetchHomeTokenCostEstimate({
    quantity: 1,
    orgId,
    options: {
      enabled: homeTokensFeatureAvailable && canAddHomeAccess,
    },
  })

  const fetchCallAssistEstimate = useFetchCallAssistActivationEstimate({
    additional_homes_quantity: 1,
    options: {
      enabled: callAssistIsActive && canAddHomeAccess,
    },
  })

  const homeTokenCostEstimate = fetchHomeTokenCostEstimate.data?.estimate
  const callassistCostEstimate = fetchCallAssistEstimate.data

  const subscriptionIsNonRenewing =
    fetchHomeTokenCostEstimate.error?.response?.data?.error_key ===
    "invalid_subscription_state"

  function handleHomeAddressStep(address: IAddress, location: ILocation) {
    setHomeLocation(location)
    setHomeAddress(address)
    setCurrentStep((currStep) => currStep + 1)
  }

  function handleOnEditClick(id: string) {
    const filteredSteps = stepper.filter((step) => !step.skip)
    const index = filteredSteps.findIndex((step) => step.id === id)
    setCurrentStep(index)
  }

  async function handleCreateHome() {
    if (!checkedTerms) {
      setCheckedTermsError(true)
      return
    }

    const body: IPostHome = {
      name: homeName,
      location: homeLocation,
      address: homeAddress,
      homegroup_id: homeGroup?.id ?? undefined,
      timezone: homeLocation
        ? getTimeZoneFromLoc(homeLocation.latitude, homeLocation.longitude)
        : getLocalTimeZone(),
      profile_id: selectedSettingsProfile.id || profiles[0]?.id,
    }

    try {
      const { home_id } = await postHome.mutateAsync({ orgId, body })
      return navigate(Routes.HomeSetup.location(home_id))
    } catch (error) {
      setError(true)
      ErrorService.captureException(error)
      return
    }
  }

  const stepper: IWizardStep[] = [
    {
      component: (
        <HomeInformationStep
          homeName={homeName}
          availableHomeTokensCount={availableHomeTokensCount}
          loading={
            fetchHomeTokens.isInitialLoading ||
            fetchHomeTokenCostEstimate.isInitialLoading ||
            fetchCallAssistEstimate.isInitialLoading ||
            getCallAssistActiveIsInitialLoading
          }
          homeTokenCostEstimate={homeTokenCostEstimate}
          error={
            fetchHomeTokens.isError ||
            fetchHomeTokenCostEstimate.isError ||
            fetchCallAssistEstimate.isError ||
            getCallAssistActiveIsError
          }
          setError={setError}
          homeTokensAvailable={homeTokensFeatureAvailable}
          addressSearchString={addressSearchString}
          autoCompleteAddress={homeAddress}
          onSubmit={(name, group, address) => {
            setHomeName(name)
            setHomeGroup(group)
            setHomeAddress(address.completedAddress)
            setAddressSearchString(address.searchString)
            setCurrentStep((step) => step + 1)
          }}
        />
      ),
      nextButtonLabel: t(langKeys.continue),
      nextButtonProps: {
        disabled: false,
        form: CREATE_HOME_FORM_ID,
      },
      hideBackButton: true,
      onNext: () => {
        setCurrentStep((currStep) => currStep + 1)
      },
    },
    {
      id: StepId.HomeAddressStep,
      component: (
        <HomeAddressStep
          onSuccess={handleHomeAddressStep}
          address={homeAddress}
          setupForGuardService={false}
        />
      ),
      nextButtonProps: {
        form: HOME_ADDRESS_FORM_ID,
      },
      nextButtonLabel: t(langKeys.continue),
    },
    {
      component: (
        <HomeMapStep
          onChangeLocation={setHomeLocation}
          location={homeLocation}
        />
      ),
      id: StepId.HomeMapStep,
      nextButtonLabel: t(langKeys.continue),
    },
    {
      component: (
        <SelectSettingsProfileStep
          profiles={profiles}
          value={selectedSettingsProfile}
          onChange={setSelectedSettingsProfile}
        />
      ),
      id: StepId.SelectSettingsProfileStep,
      nextButtonLabel: t(langKeys.continue),
      nextButtonProps: {
        disabled: !selectedSettingsProfile.id,
      },
      skip: profiles.length <= 1,
    },
    {
      component: (
        <CreateHomeConfirmStep
          location={homeLocation}
          address={homeAddress}
          settingsProfile={selectedSettingsProfile}
          handleOnEditClick={handleOnEditClick}
          priceInfo={fetchCreateHomePrice.data}
          hasStarterPlan={current_plan === PLAN.starter}
          error={
            postHome.isError ||
            fetchCreateHomePrice.isError ||
            error ||
            fetchHomeTokens.isError ||
            (homeTokensFeatureAvailable && !homeTokenCostEstimate)
          }
          showSettingsProfileSection={profiles.length > 1}
          homeTokensFeatureAvailable={
            homeTokensAvailability.available && homeTokensAvailability.ready
          }
          homeTokenCostEstimate={homeTokenCostEstimate}
          callAssistCostEstimate={callassistCostEstimate}
          hasCallAssistEnabled={callAssistIsActive}
          availableHomeTokensCount={availableHomeTokensCount}
          selectedHomeGroup={homeGroup}
          checkedTerms={checkedTerms}
          onCheckTerms={() => {
            setCheckedTerms((prev) => !prev)
            setCheckedTermsError(false)
          }}
          checkedTermsError={checkedTermsError}
          // We want to hade the vat exempt copy until the create home estimation endpoint is created
          // right now we do not have a way of getting this information
          vatExempt={true}
        />
      ),
      nextButtonLabel: t(langKeys.create_home),
      border: false,
      onNext: handleCreateHome,
      nextButtonProps: {
        loading:
          postHome.isLoading ||
          fetchCreateHomePrice.isInitialLoading ||
          fetchHomeTokenCostEstimate.isInitialLoading ||
          fetchCallAssistEstimate.isInitialLoading ||
          getCallAssistActiveIsInitialLoading ||
          (homeTokensFeatureAvailable && fetchHomeTokens.isInitialLoading),
        disabled:
          error ||
          fetchHomeTokenCostEstimate.isError ||
          fetchCallAssistEstimate.isError ||
          getCallAssistActiveIsError ||
          (homeTokensFeatureAvailable && !homeTokenCostEstimate),
      },
      onBack: () => {
        setError(false)
        postHome.reset()
        setCurrentStep((currStep) => currStep - 1)
        setCheckedTermsError(false)
      },
    },
  ]

  if (subscriptionIsNonRenewing) {
    return (
      <ReactivateBlockerDialog
        open={true}
        onClose={() => {
          goBack({ defaultPath: Routes.Homes.location() })
        }}
      />
    )
  }

  if (!canAddHomeAccess) {
    return (
      <NotEnoughPermissionsDialog
        open={true}
        onClose={() => {
          goBack({ defaultPath: Routes.Homes.location() })
        }}
      />
    )
  }

  if (createHomeAvailability.ready && !createHomeAvailability.available) {
    return org.current_subscription_status !== SubscriptionStatus.CANCELLED ? (
      <StoreBlockerDialog
        open={true}
        onClose={() => goBack({ defaultPath: Routes.Homes.location() })}
        onConfirm={() => {
          window.location.href = isProdEnv
            ? HREF_MINUT_STORE
            : HREF_MINUT_STAGING_STORE
        }}
      />
    ) : (
      <UpgradeBlockerDialog
        open={true}
        onClose={() => goBack({ defaultPath: Routes.Homes.location() })}
      />
    )
  }

  return (
    <FullscreenWizard
      title={t(langKeys.create_home)}
      steps={stepper}
      onClose={() => goBack({ defaultPath: Routes.Homes.location() })}
      currentStep={currentStep}
      onNext={() => setCurrentStep((currStep) => currStep + 1)}
      onBack={() => setCurrentStep((currStep) => currStep - 1)}
    />
  )
}
