export function getTotalPrice({
  homeTokenAmount,
  callAssistAmount,
  hasHomeTokens,
  hasCallAssistEnabled,
}: {
  homeTokenAmount: number
  callAssistAmount: number
  hasHomeTokens: boolean
  hasCallAssistEnabled: boolean
}) {
  if (!hasHomeTokens && hasCallAssistEnabled) {
    return homeTokenAmount + callAssistAmount
  }

  if (!hasHomeTokens && !hasCallAssistEnabled) {
    return homeTokenAmount
  }

  if (hasHomeTokens && hasCallAssistEnabled) {
    return callAssistAmount
  }

  return 0
}
