import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function BilledNow({
  formattedTotalPrice,
  vatExempt,
}: {
  formattedTotalPrice: string
  vatExempt: boolean
}) {
  const { t, langKeys } = useTranslate()

  return (
    <BilledNowWrapper>
      <FlexBox>
        <MText variant="subtitle">{t(langKeys.billed_now)}</MText>
        <PriceBox>
          <MText variant="subtitle">{formattedTotalPrice}</MText>
          {!vatExempt && (
            <MText variant="bodyS" color="tertiary">
              {t(langKeys.total_includes_vat)}
            </MText>
          )}
        </PriceBox>
      </FlexBox>
      <MText variant="bodyS" color="secondary">
        {t(langKeys.create_home_confirmation_terms, {
          price: formattedTotalPrice,
        })}
      </MText>
    </BilledNowWrapper>
  )
}

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`

const PriceBox = styled.div`
  text-align: right;
`

const BilledNowWrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`
