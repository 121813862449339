import styled from "styled-components"

import { BilledNow } from "src/components/Homes/CreateHomeWizard/BillingSummary/BilledNow"
import { getFormattedHomePriceForBillingPeriod } from "src/data/homes/logic/homeUtil"
import { IFetchCreateHomePriceResponse } from "src/data/homes/types/homeTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Divider } from "src/ui/Divider/Divider"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function BillingUpdateTable({
  homePriceInfo,
}: {
  homePriceInfo: IFetchCreateHomePriceResponse
}) {
  const { t, langKeys } = useTranslate()
  const hasAddons = homePriceInfo.breakdown.length > 1

  const _billingPeriod = homePriceInfo.billing_period || "year"

  const formattedHomePrice = getFormattedHomePriceForBillingPeriod({
    price: homePriceInfo.breakdown[0].price,
    isPriceInCents: true,
    currencyCode: homePriceInfo.currency_code,
    billingPeriod: _billingPeriod,
    t,
  })

  return (
    <MCard border boxShadow={false}>
      <BreakdownWrapper>
        <FlexBox>
          <MText variant="subtitle">
            {t(langKeys.create_home_confirmation_creating_home)}
          </MText>

          <MText>
            {_billingPeriod === "month"
              ? formattedHomePrice.formattedMonthlyPrice
              : formattedHomePrice.formattedAnnualPrice}
          </MText>
        </FlexBox>
        {hasAddons &&
          homePriceInfo.breakdown.slice(1).map((a) => {
            return (
              <FlexBox key={a.id}>
                <MText variant="subtitle">{a.name}</MText>

                <MText>
                  {a.formatted_price}/{_billingPeriod}
                </MText>
              </FlexBox>
            )
          })}
      </BreakdownWrapper>
      <Divider $margin={spacing.M} />
      <BilledNow
        formattedTotalPrice={homePriceInfo.formatted_price}
        vatExempt={homePriceInfo.vat_exempt}
      />
    </MCard>
  )
}

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`

const BreakdownWrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`
