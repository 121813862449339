import styled from "styled-components"

import { AsyncDropdown } from "src/components/Dropdown/AsyncDropdown"
import { IProfileResponse } from "src/data/homeProfiles/types/homeProfilesTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Heading2Mixin } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export interface ISelectedOption {
  id: string
  name: string
}

export function SelectSettingsProfileStep({
  profiles,
  value,
  onChange,
}: {
  profiles: IProfileResponse[]
  value: ISelectedOption
  onChange: (option: ISelectedOption) => void
}) {
  const { t, langKeys } = useTranslate()

  async function homeProfilesSearch(
    value: string
  ): Promise<{ id: string; name: string }[]> {
    try {
      return [...profiles, { id: "", name: "" }]
    } catch (e) {
      throw Error("An error fetching home settings profile list")
    }
  }

  function handleOnSelected(option: ISelectedOption) {
    onChange(option)
  }

  return (
    <>
      <Title>{t(langKeys.create_home_settings_profile_step_title)}</Title>

      <Description>
        {t(langKeys.create_home_settings_profile_step_body)}
      </Description>

      <AsyncDropdown
        label={t(langKeys.profile_title)}
        valueInitial={value}
        optionsInitial={[]}
        dataFetcher={homeProfilesSearch}
        onSelected={handleOnSelected}
      />
    </>
  )
}

const Title = styled.div`
  ${Heading2Mixin};
  margin-bottom: ${spacing.M};
`

const Description = styled.div`
  margin-bottom: ${spacing.XL2};
`
