import { useState } from "react"
import styled from "styled-components"

import { TextField } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"

import {
  HomeGroupsDropdown,
  IHomeGroupDropdownProps,
} from "src/components/Dropdown/HomeGroupsDropdown"
import {
  CREATE_HOME_FORM_ID,
  EMPTY_AUTOCOMPLETE_ADDRESS,
} from "src/components/Homes/CreateHomeWizard/createHomeConstants"
import { GooglePlacesAutoComplete } from "src/components/Homes/GooglePlacesAutoComplete"
import { extractAddressData } from "src/data/homeAddress/logic/homeAddressDataUtils"
import { useFetchHomeGroups } from "src/data/homeGroups/queries/homeGroupQueries"
import {
  HomeGroupRole,
  TMaybeHomeGroup,
} from "src/data/homeGroups/types/homeGroupTypes"
import { getFormattedHomePriceForBillingPeriod } from "src/data/homes/logic/homeUtil"
import { THomeTokenCostEstimate } from "src/data/homes/types/homeTokenTypes"
import { IAddress } from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { TextButton } from "src/ui/Button/TextButton"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function HomeInformationStep({
  homeName: initialHomeName,
  addressSearchString: initialAddressString,
  autoCompleteAddress: initialAutoCompleteAddress,
  availableHomeTokensCount,
  loading,
  homeTokenCostEstimate,
  error,
  setError,
  homeTokensAvailable,
  onSubmit,
}: {
  homeName: string
  addressSearchString: string
  availableHomeTokensCount: number
  loading: boolean
  autoCompleteAddress: IAddress | undefined
  homeTokenCostEstimate?: THomeTokenCostEstimate
  error: boolean
  setError: (value: boolean) => void
  homeTokensAvailable: boolean
  onSubmit: (
    homeName: string,
    homeGroup: TMaybeHomeGroup,
    address: { completedAddress: IAddress; searchString: string }
  ) => void
}) {
  const { t, langKeys } = useTranslate()
  const { org } = useOrganization()
  const [autoCompleteAddress, setAutoCompleteAddress] = useState<IAddress>(
    EMPTY_AUTOCOMPLETE_ADDRESS
  )

  const [homeGroup, setHomeGroup] = useState<TMaybeHomeGroup>(null)

  const homeGroupProps: IHomeGroupDropdownProps = {
    onSelect: setHomeGroup,
    value: homeGroup,
    baseFilter: { role: HomeGroupRole.ADMIN },
  }

  const [homeName, setHomeName] = useState(initialHomeName)
  const [addressSearchString, setAddressSearchString] =
    useState(initialAddressString)

  const [useManualAddress, setUseManualAddress] = useState(false)

  const fetchHomeGroups = useFetchHomeGroups({
    orgId: org.id,
    filter: { limit: 1 }, // We don't care about which homegroups, just that any exists
  })

  const isLoading = loading || fetchHomeGroups.isLoading

  function getAddress({
    address,
    addressComponents,
  }: {
    address: string
    addressComponents: google.maps.GeocoderAddressComponent[] | null
  }) {
    if (!addressComponents) {
      setAddressSearchString("")
      setAutoCompleteAddress(extractAddressData([]))
      return
    }
    setAddressSearchString(address)
    setAutoCompleteAddress(extractAddressData(addressComponents))
  }

  if (isLoading) {
    return <InformationStepSkeleton />
  }

  if (
    error ||
    fetchHomeGroups.isError ||
    (homeTokensAvailable && !homeTokenCostEstimate)
  ) {
    setError(true)

    return (
      <div>
        <MText variant="heading2" marginBottom={spacing.XS}>
          {t(langKeys.create_home_information_step_title, {
            organization: org.name,
          })}
        </MText>

        <StyledMAlert type="error" fullWidth>
          {t(langKeys.failed_something_went_wrong)}
        </StyledMAlert>
      </div>
    )
  }

  return (
    <div>
      <MText variant="heading2" marginBottom={spacing.XS}>
        {t(langKeys.create_home_information_step_title, {
          organization: org.name,
        })}
      </MText>

      {homeTokensAvailable && homeTokenCostEstimate && (
        <HomeTokenInformation
          availableHomeTokensCount={availableHomeTokensCount}
          homeTokenCostEstimate={homeTokenCostEstimate}
        />
      )}

      <FormBox
        id={CREATE_HOME_FORM_ID}
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit(homeName, homeGroup, {
            completedAddress: autoCompleteAddress,
            searchString: addressSearchString,
          })
        }}
      >
        <TextField
          label={t(langKeys.home_name)}
          variant="outlined"
          autoComplete="off"
          fullWidth
          required
          value={homeName}
          onChange={(e) => setHomeName(e.target.value)}
          onBlur={(e) => setHomeName(e.target.value.trim())}
        />

        {fetchHomeGroups.data?.paging.total_count > 0 && (
          <HomeGroupsDropdown {...homeGroupProps} />
        )}

        <AutocompleteGrid>
          <GooglePlacesAutoComplete
            currentLocation={addressSearchString}
            onLocationSelected={getAddress}
            label={t(langKeys.address)}
            required={!useManualAddress}
          />
          <div>
            <TextButton
              type="submit"
              form={CREATE_HOME_FORM_ID}
              onClick={() => {
                setUseManualAddress(true)
                setAddressSearchString("")
                setAutoCompleteAddress(EMPTY_AUTOCOMPLETE_ADDRESS)
              }}
            >
              {t(langKeys.create_home_enter_address_manually)}
            </TextButton>
          </div>
        </AutocompleteGrid>
      </FormBox>
    </div>
  )
}

function HomeTokenInformation({
  availableHomeTokensCount,
  homeTokenCostEstimate,
}: {
  availableHomeTokensCount: number
  homeTokenCostEstimate: THomeTokenCostEstimate
}) {
  const { t, langKeys } = useTranslate()

  const price = getFormattedHomePriceForBillingPeriod({
    price: homeTokenCostEstimate.amount,
    isPriceInCents: true,
    currencyCode: homeTokenCostEstimate.currency,
    billingPeriod: homeTokenCostEstimate.billing_period,
    t,
  })

  if (availableHomeTokensCount > 0) {
    return (
      <MText marginBottom={spacing.XL}>
        {t(langKeys.adding_new_home_token_info, {
          tokens: availableHomeTokensCount,
          count: availableHomeTokensCount,
        })}
      </MText>
    )
  }

  return (
    <MText marginBottom={spacing.XL}>
      {t(langKeys.organization_homes_create_unprepaid_home_info, {
        price: price.monthlyPrice,
      })}
    </MText>
  )
}

function InformationStepSkeleton() {
  return (
    <FormBox>
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
      <Skeleton variant="rect" />
    </FormBox>
  )
}

const FormBox = styled.form`
  display: grid;
  gap: ${spacing.L};
`

const AutocompleteGrid = styled.div`
  display: grid;
  gap: ${spacing.XS};
`

const StyledMAlert = styled(MBanner)`
  margin-top: ${spacing.M};
`
