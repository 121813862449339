import { BillingSummary } from "src/components/Homes/CreateHomeWizard/BillingSummary/BillingSummary"
import { BillingUpdateTable } from "src/components/Homes/CreateHomeWizard/BillingUpdateTable"
import { TCallAssistEstimateResponse } from "src/data/callAssist/types/callAssistTypes"
import { THomeTokenCostEstimate } from "src/data/homes/types/homeTokenTypes"
import { IFetchCreateHomePriceResponse } from "src/data/homes/types/homeTypes"

export function BillingDescription({
  homePriceInfo,
  homeTokensFeatureAvailable,
  homeTokenCostEstimate,
  callAssistCostEstimate,
  hasCallAssistEnabled,
  availableHomeTokensCount,
  vatExempt,
}: {
  callAssistCostEstimate?: TCallAssistEstimateResponse
  hasCallAssistEnabled: boolean
  homePriceInfo?: IFetchCreateHomePriceResponse
  homeTokensFeatureAvailable: boolean
  homeTokenCostEstimate?: THomeTokenCostEstimate
  availableHomeTokensCount: number
  vatExempt: boolean
}) {
  // Addons will be handled in a different PR. The story for that PR will be
  // under PRD-3787
  if (homeTokensFeatureAvailable) {
    return (
      <BillingSummary
        callAssistCostEstimate={callAssistCostEstimate}
        hasCallAssistEnabled={hasCallAssistEnabled}
        homeTokenCostEstimate={homeTokenCostEstimate}
        availableHomeTokensCount={availableHomeTokensCount}
        vatExempt={vatExempt}
      />
    )
  }

  if (!homePriceInfo) {
    return null
  }

  return <BillingUpdateTable homePriceInfo={homePriceInfo} />
}
