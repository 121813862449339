import styled from "styled-components"

import { IconButton } from "@material-ui/core"

import { useTranslate } from "src/i18n/useTranslate"
import { colorsV2 } from "src/ui/colors"
import PenIcon from "src/ui/icons/pen-outlined.svg"
import { MText } from "src/ui/MText"

export function ConfirmationMonitoringProfile({
  profileName,
  onEditClick,
}: {
  profileName: string
  onEditClick?: () => void
}) {
  const { t, langKeys } = useTranslate()

  return (
    <div>
      <CardHeader>
        <MText variant="subtitle">{t(langKeys.profile_title)}</MText>
        <IconButton size="small" onClick={onEditClick}>
          <PenIcon width={24} color={colorsV2.textPrimary} />
        </IconButton>
      </CardHeader>
      <MText>{profileName}</MText>
    </div>
  )
}

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
