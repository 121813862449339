import { BilledNow } from "src/components/Homes/CreateHomeWizard/BillingSummary/BilledNow"
import { BillingBreakdown } from "src/components/Homes/CreateHomeWizard/BillingSummary/BillingBreakdown"
import { getTotalPrice } from "src/components/Homes/CreateHomeWizard/BillingSummary/billingUtils"
import { TCallAssistEstimateResponse } from "src/data/callAssist/types/callAssistTypes"
import { getFormattedHomePriceForBillingPeriod } from "src/data/homes/logic/homeUtil"
import { THomeTokenCostEstimate } from "src/data/homes/types/homeTokenTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Divider } from "src/ui/Divider/Divider"
import { MCard } from "src/ui/MCard/MCard"
import { spacing } from "src/ui/spacing"

export function BillingSummary({
  callAssistCostEstimate,
  hasCallAssistEnabled,
  homeTokenCostEstimate,
  availableHomeTokensCount,
  vatExempt,
}: {
  callAssistCostEstimate?: TCallAssistEstimateResponse
  hasCallAssistEnabled: boolean
  homeTokenCostEstimate?: THomeTokenCostEstimate
  availableHomeTokensCount: number
  vatExempt: boolean
}) {
  const { t } = useTranslate()

  if (!homeTokenCostEstimate) {
    return null
  }

  const showBilledNow = availableHomeTokensCount === 0 || hasCallAssistEnabled
  const totalPrice = getTotalPrice({
    homeTokenAmount: homeTokenCostEstimate.amount,
    callAssistAmount: callAssistCostEstimate?.due_today ?? 0,
    hasCallAssistEnabled: hasCallAssistEnabled,
    hasHomeTokens: availableHomeTokensCount > 0,
  })

  return (
    <MCard padding={spacing.M} border boxShadow={false}>
      <BillingBreakdown
        callAssistCostEstimate={callAssistCostEstimate}
        hasCallAssistEnabled={hasCallAssistEnabled}
        homeTokenCostEstimate={homeTokenCostEstimate}
        availableHomeTokensCount={availableHomeTokensCount}
      />
      {showBilledNow && (
        <>
          <Divider $margin={spacing.M} />
          <BilledNow
            formattedTotalPrice={
              getFormattedHomePriceForBillingPeriod({
                price: totalPrice,
                isPriceInCents: true,
                currencyCode: homeTokenCostEstimate.currency,
                billingPeriod: "year",
                t,
              }).annualPrice
            }
            vatExempt={vatExempt}
          />
        </>
      )}
    </MCard>
  )
}
