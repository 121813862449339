import styled from "styled-components"

import { Checkbox, FormControlLabel } from "@material-ui/core"

import { BillingDescription } from "src/components/Homes/CreateHomeWizard/BillingDescription"
import { ConfirmationHomeDetails } from "src/components/Homes/CreateHomeWizard/ConfirmationHomeDetails"
import { ConfirmationMonitoringProfile } from "src/components/Homes/CreateHomeWizard/ConfirmationMonitoringProfile"
import { ISelectedOption } from "src/components/Homes/CreateHomeWizard/SelectSettingsProfileStep"
import { HREF_MINUT_SUBSCRIPTION_TERMS } from "src/constants/hrefs"
import { TCallAssistEstimateResponse } from "src/data/callAssist/types/callAssistTypes"
import { TMaybeHomeGroup } from "src/data/homeGroups/types/homeGroupTypes"
import { THomeTokenCostEstimate } from "src/data/homes/types/homeTokenTypes"
import {
  IAddress,
  IFetchCreateHomePriceResponse,
  ILocation,
} from "src/data/homes/types/homeTypes"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { getHomeGroupAccessLogic } from "src/data/user/logic/accessLogic"
import { useTranslate } from "src/i18n/useTranslate"
import { colorsV2 } from "src/ui/colors"
import { Divider } from "src/ui/Divider/Divider"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { StepId } from "./createHomeTypes"

export function CreateHomeConfirmStep({
  location,
  address,
  settingsProfile,
  handleOnEditClick,
  priceInfo,
  error,
  showSettingsProfileSection,
  hasStarterPlan,
  homeTokensFeatureAvailable,
  homeTokenCostEstimate,
  availableHomeTokensCount,
  selectedHomeGroup,
  callAssistCostEstimate,
  hasCallAssistEnabled,
  checkedTerms,
  onCheckTerms,
  checkedTermsError,
  vatExempt,
}: {
  location: ILocation
  address: IAddress
  settingsProfile: ISelectedOption
  handleOnEditClick: (id: string) => void
  showSettingsProfileSection: boolean
  hasStarterPlan: boolean
  priceInfo: IFetchCreateHomePriceResponse | undefined
  error?: boolean
  homeTokensFeatureAvailable: boolean
  homeTokenCostEstimate?: THomeTokenCostEstimate
  callAssistCostEstimate?: TCallAssistEstimateResponse
  hasCallAssistEnabled: boolean
  availableHomeTokensCount: number
  selectedHomeGroup: TMaybeHomeGroup
  checkedTerms: boolean
  onCheckTerms: () => void
  checkedTermsError: boolean
  vatExempt: boolean
}) {
  const { t, langKeys } = useTranslate()

  const { orgAccessLogic } = useOrganization()

  const hasAdminAccess = orgAccessLogic.hasAdminAccess

  const hasHomeGroupAdminAcess = getHomeGroupAccessLogic({
    role: selectedHomeGroup?.user_role,
  })

  const showBillingDescription = hasAdminAccess || hasHomeGroupAdminAcess

  return (
    <>
      <MText variant="heading2" marginBottom={spacing.XL}>
        {t(langKeys.create_home_confirm_step_title)}
      </MText>

      <Box>
        <MCard border boxShadow={false}>
          <ConfirmationHomeDetails
            location={location}
            address={address}
            onEdit={() => handleOnEditClick(StepId.HomeAddressStep)}
          />
          {showSettingsProfileSection && (
            <>
              <Divider $margin={spacing.M} />
              <ConfirmationMonitoringProfile
                profileName={settingsProfile.name}
                onEditClick={() =>
                  handleOnEditClick(StepId.SelectSettingsProfileStep)
                }
              />
            </>
          )}
        </MCard>

        {showBillingDescription && (
          <BillingDescription
            homePriceInfo={priceInfo}
            callAssistCostEstimate={callAssistCostEstimate}
            hasCallAssistEnabled={hasCallAssistEnabled}
            homeTokensFeatureAvailable={homeTokensFeatureAvailable}
            homeTokenCostEstimate={homeTokenCostEstimate}
            availableHomeTokensCount={availableHomeTokensCount}
            vatExempt={vatExempt}
          />
        )}
      </Box>
      <TermsWrapper>
        <MBanner type="info" fullWidth>
          <FormControlLabel
            label={
              <MText variant="bodyS">
                {t(langKeys.create_home_confirmation_accept_body)}{" "}
                <ExternalLink href={HREF_MINUT_SUBSCRIPTION_TERMS}>
                  {t(langKeys.learn_more)}
                </ExternalLink>
              </MText>
            }
            control={
              <StyledCheckbox
                checked={checkedTerms}
                onChange={onCheckTerms}
                $error={checkedTermsError}
              />
            }
          />
        </MBanner>
        {checkedTermsError && (
          <MBanner type="error" fullWidth>
            {t(langKeys.create_home_confirmation_accept_error)}
          </MBanner>
        )}
      </TermsWrapper>

      {error && (
        <StyledMAlert type="error" fullWidth>
          {t(langKeys.failed_something_went_wrong)}
        </StyledMAlert>
      )}
    </>
  )
}

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
`

const TermsWrapper = styled.div`
  display: grid;
  gap: ${spacing.XS};
  margin-top: ${spacing.L};
`

const StyledMAlert = styled(MBanner)`
  margin-top: ${spacing.M};
`

const StyledCheckbox = styled(Checkbox)<{ $error: boolean }>`
  color: ${({ $error }) => $error && colorsV2.systemError};
`
