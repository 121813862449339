import styled from "styled-components"

import { getFormattedCallAssistCost } from "src/components/CallAssistActivate/getFormattedCallAssistCost"
import { TCallAssistEstimateResponse } from "src/data/callAssist/types/callAssistTypes"
import { getFormattedHomePriceForBillingPeriod } from "src/data/homes/logic/homeUtil"
import { THomeTokenCostEstimate } from "src/data/homes/types/homeTokenTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function BillingBreakdown({
  availableHomeTokensCount,
  hasCallAssistEnabled,
  callAssistCostEstimate,
  homeTokenCostEstimate,
}: {
  availableHomeTokensCount: number
  hasCallAssistEnabled?: boolean
  callAssistCostEstimate?: TCallAssistEstimateResponse
  homeTokenCostEstimate: THomeTokenCostEstimate
}) {
  const { t, langKeys } = useTranslate()

  const homePrice = getFormattedHomePriceForBillingPeriod({
    price: homeTokenCostEstimate.amount,
    isPriceInCents: true,
    currencyCode: homeTokenCostEstimate.currency,
    billingPeriod: homeTokenCostEstimate.billing_period,
    t,
  })

  const callAssistEstimate = callAssistCostEstimate
    ? getFormattedCallAssistCost(callAssistCostEstimate)
    : null

  const decisionLineItem = hasCallAssistEnabled && callAssistEstimate && (
    <FlexBox>
      <MText variant="subtitle">{t(langKeys.adding_call_assist_title)}</MText>
      <PriceBox>
        <MText>
          {callAssistEstimate.unitPriceMonthly}/
          {t(langKeys.month).toLowerCase()}
        </MText>
        <MText variant="bodyS" color="secondary">
          {t(langKeys.billed_as)} {callAssistEstimate.unitPriceYearly}/
          {t(langKeys.year).toLocaleLowerCase()}
        </MText>
      </PriceBox>
    </FlexBox>
  )

  if (availableHomeTokensCount > 0) {
    return (
      <BreakdownWrapper>
        <FlexBox>
          <MText variant="subtitle">
            {t(langKeys.create_home_confirmation_activating_home)}
          </MText>

          <MText>
            {t(langKeys.organization_create_home_using_tokens, {
              count: availableHomeTokensCount,
              tokens: availableHomeTokensCount,
            })}
          </MText>
        </FlexBox>
        {decisionLineItem}
      </BreakdownWrapper>
    )
  }

  return (
    <BreakdownWrapper>
      <FlexBox>
        <MText variant="subtitle">
          {t(langKeys.create_home_confirmation_creating_home)}
        </MText>

        <PriceBox>
          <MText>{homePrice.formattedMonthlyPrice}</MText>

          <MText variant="bodyS" color="secondary">
            {t(langKeys.billed_as)} {homePrice.formattedAnnualPrice}
          </MText>
        </PriceBox>
      </FlexBox>
      {decisionLineItem}
    </BreakdownWrapper>
  )
}

const FlexBox = styled.div`
  display: flex;
  justify-content: space-between;
`

const PriceBox = styled.div`
  text-align: right;
`

const BreakdownWrapper = styled.div`
  display: grid;
  gap: ${spacing.M};
`
